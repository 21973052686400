<template>
  <div>
    <b-sidebar
      sidebar-class="sidebar-lg"
      visible
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="closeModal(false)"
    >
      <template #default="{ hide }">

        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            class="mb-0"
          >
            {{ $t('Nova contra-proposta') }}
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              :size="sizeIconsAction"
              @click="hide"
            />
          </div>
        </div>
        <b-overlay
          :show="processingAjax"
          rounded="sm"
        >
          <vue-perfect-scrollbar
            ref="scrollForm"
            class="b-sidebar-scrollarea-form"
            :settings="configSettingsScroll"
          >
            <b-container
              id="container"
              class="pt-2 pb-0 pl-2 pr-2"
              fluid
            >
              <b-form
                ref="formSaveProposal"
                @submit.prevent
              >
                <validation-observer
                  #default="{ }"
                  ref="formSaveProposalValidator"
                >
                  <b-row>
                    <b-col md="12">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-group
                          :label="$t('Motivo')"
                          :class="errors.length > 0 ? 'is-invalid':null"
                        >
                          <v-select
                            v-model="form_data.motivo"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="typeReasons"
                            append-to-body
                            :calculate-position="withPopper"
                            label="desc"
                            item-text="desc"
                            item-value="id"
                          >
                            <template #option="{ desc }">
                              {{ desc }}
                            </template>
                            <div slot="no-options">
                              {{ $t('Sem resultados') }}
                            </div>
                          </v-select>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>

                  <div v-if="typeof form_data.motivo === 'undefined' || form_data.motivo === null || Number(form_data.motivo.id) === 0">
                    <b-row class="mt-2">
                      <b-col md="12">
                        <h4>
                          {{ $t('Proposta de negócio') }}
                        </h4>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col md="12">
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                        >
                          <b-form-group
                            :label="$t('Tipo de negócio')"
                            :class="errors.length > 0 ? 'is-invalid':null"
                          >
                            <v-select
                              v-model="form_data.sw131s03"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options="typeBusiness"
                              append-to-body
                              :calculate-position="withPopper"
                              :disabled="true"
                              label="sw007s02"
                              item-text="sw007s02"
                              item-value="sw007s01"
                            >
                              <template #option="{ sw007s02 }">
                                {{ sw007s02 }}
                              </template>
                              <div slot="no-options">
                                {{ $t('Sem resultados') }}
                              </div>
                            </v-select>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col md="12">
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                        >
                          <b-form-group
                            :label="$t('Valor')"
                            :class="errors.length > 0 ? 'is-invalid':null"
                          >
                            <b-input-group
                              :prepend="symbolMoney"
                              append=""
                              class="input-group-merge"
                              :class="errors.length > 0 ? 'is-invalid':null"
                            >
                              <b-form-input
                                v-model.lazy="form_data.sw131s04"
                                v-currency="configMoney"
                                placeholder=""
                                autocomplete="off"
                                :class="errors.length > 0 ? 'is-invalid':null"
                                @input="updateField($event, true)"
                              />
                            </b-input-group>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col md="12">
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                        >
                          <b-form-group
                            :label="$t('Data da contra-proposta')"
                            :class="errors.length > 0 ? 'is-invalid':null"
                          >
                            <date-picker
                              v-model="form_data.sw131s05"
                              value-type="format"
                              :input-class="{'form-control' : true, 'is-invalid': errors.length > 0 }"
                              type="date"
                              :editable="false"
                              :clearable="true"
                              :lang="langCalendar"
                              :append-to-body="true"
                              prefix-class="sw"
                              :disabled-date="disabledMaxDate"
                            >
                              <template #icon-clear>
                                <v-select-deselect />
                              </template>
                            </date-picker>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>

                    <b-row class="mt-2">
                      <b-col md="12">
                        <h4>
                          {{ $t('Entrega no contrato de promessa de compra e venda') }}
                        </h4>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col md="12">
                        <b-form-group
                          :label="$t('Valor')"
                        >
                          <b-input-group
                            :prepend="symbolMoney"
                            append=""
                            class="input-group-merge"
                          >
                            <b-form-input
                              v-model.lazy="form_data.sw131s06"
                              v-currency="configMoney"
                              placeholder=""
                              autocomplete="off"
                              @input="updateField"
                            />
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col md="12">
                        <b-form-group
                          :label="$t('Data')"
                        >
                          <date-picker
                            v-model="form_data.sw131s07"
                            value-type="format"
                            input-class="form-control"
                            type="date"
                            :editable="false"
                            :clearable="true"
                            :lang="langCalendar"
                            :append-to-body="true"
                            prefix-class="sw"
                            :disabled-date="disabledMaxDateEntrega"
                          >
                            <template #icon-clear>
                              <v-select-deselect />
                            </template>
                          </date-picker>
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <div
                      v-for="(row, index) in aReforcos"
                      :key="`reforco-${index}`"
                    >
                      <b-row class="mt-2">
                        <b-col md="12">
                          <h4>
                            {{ $t('Reforço de sinal') }} - {{ index + 1 }}
                          </h4>
                        </b-col>
                      </b-row>

                      <b-row :key="`reforco-valor-${index}`">
                        <b-col md="12">
                          <b-form-group
                            :label="$t('Valor')"
                          >
                            <b-input-group
                              :prepend="symbolMoney"
                              append=""
                              class="input-group-merge"
                            >
                              <b-form-input
                                v-model.lazy="row.valor"
                                v-currency="configMoney"
                                placeholder=""
                                autocomplete="off"
                                @input="updateField"
                              />
                            </b-input-group>
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row :key="`reforco-data-${index}`">
                        <b-col md="12">
                          <b-form-group
                            :label="$t('Data')"
                          >
                            <date-picker
                              v-model="row.data"
                              value-type="format"
                              input-class="form-control"
                              type="date"
                              :editable="false"
                              :clearable="true"
                              :lang="langCalendar"
                              :append-to-body="true"
                              prefix-class="sw"
                              :disabled-date="disabledMaxDateReforco"
                              @input="changeData"
                            >
                              <template #icon-clear>
                                <v-select-deselect />
                              </template>
                            </date-picker>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row v-if="aReforcos.length > 0 && (index + 1) !== aReforcos.length">
                        <b-col md="12">
                          <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-danger"
                            class="btn-icon mb-1"
                            @click="removeReforco(index)"
                          >
                            <span class="align-middle">{{ $t('Eliminar') }}</span>
                          </b-button>
                        </b-col>
                      </b-row>
                    </div>

                    <b-row>
                      <b-col md="12">
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-primary"
                          class="btn-icon"
                          @click="addReforco"
                        >
                          <span class="align-middle">{{ $t('Adicionar reforço') }}</span>
                        </b-button>
                      </b-col>
                    </b-row>

                    <b-row class="mt-2">
                      <b-col md="12">
                        <h4>
                          {{ $t('Valor a entregar no ato da escritura') }}
                        </h4>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col md="12">
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                        >
                          <b-form-group
                            :label="$t('Valor')"
                            :class="errors.length > 0 ? 'is-invalid':null"
                          >
                            <b-input-group
                              :prepend="symbolMoney"
                              append=""
                              class="input-group-merge"
                              :disabled="true"
                              :class="errors.length > 0 ? 'is-invalid':null"
                            >
                              <b-form-input
                                v-model.lazy="form_data.sw131s26"
                                v-currency="configMoney"
                                placeholder=""
                                autocomplete="off"
                                :disabled="true"
                                :class="errors.length > 0 ? 'is-invalid':null"
                              />
                            </b-input-group>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col md="12">
                        <validation-provider
                          #default="{ errors }"
                          rules="required"
                        >
                          <b-form-group
                            :label="$t('Data')"
                            :class="errors.length > 0 ? 'is-invalid':null"
                          >
                            <date-picker
                              v-model="form_data.sw131s28"
                              value-type="format"
                              :input-class="{'form-control' : true, 'is-invalid': errors.length > 0 }"
                              type="date"
                              :editable="false"
                              :clearable="true"
                              :lang="langCalendar"
                              :append-to-body="true"
                              prefix-class="sw"
                              :disabled-date="disabledMaxDateEscritura"
                            >
                              <template #icon-clear>
                                <v-select-deselect />
                              </template>
                            </date-picker>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col md="12">
                        <b-form-group
                          :label="$t('Condições complementares')"
                        >
                          <b-form-textarea
                            v-model="form_data.sw131s08"
                            placeholder=""
                            rows="4"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>

                    <div v-if="typeof proposal === 'undefined' || proposal.sw130s12 === null || proposal.sw130s12 === '' || [5,6,7].includes(Number(proposal.sw130s12))">
                      <b-row class="mt-2">
                        <b-col md="12">
                          <h4>
                            {{ $t('Alteração da comissão') }}
                          </h4>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col md="12">
                          <b-form-group
                            :label="$t('Comissão contratada no CMI')"
                          >
                            <b-input-group
                              :prepend="symbolMoney"
                              append=""
                              class="input-group-merge"
                              :disabled="true"
                            >
                              <b-form-input
                                v-model="form_data.comissao"
                                placeholder=""
                                autocomplete="off"
                                :disabled="true"
                              />
                            </b-input-group>
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col md="12 mb-1">
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="outline-primary"
                            block
                            @click="flagChangeComission = true"
                          >
                            {{ $t('Alterar comissão') }}
                          </b-button>
                        </b-col>
                      </b-row>

                      <b-row v-if="flagChangeComission === true">
                        <b-col md="12">
                          <b-form-group
                            :label="$t('Novo valor de comissão')"
                          >
                            <b-input-group
                              :prepend="symbolMoney"
                              append=""
                              class="input-group-merge"
                            >
                              <b-form-input
                                v-model.lazy="form_data.sw131s24"
                                v-currency="configMoney"
                                placeholder=""
                                autocomplete="off"
                              />
                            </b-input-group>
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row v-if="flagChangeComission === true">
                        <b-col md="12">
                          <b-form-group
                            :label="$t('Justifique o seu pedido de alteração de comissão (min. 250 caracteres)')"
                          >
                            <b-form-textarea
                              v-model="form_data.sw131s17"
                              placeholder=""
                              rows="4"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </div>
                  </div>

                  <div v-if="typeof form_data.motivo !== 'undefined' && form_data.motivo !== null && Number(form_data.motivo.id) === 1">
                    <b-row>
                      <b-col md="12">
                        <validation-provider
                          #default="{ errors }"
                          :rules="(typeof form_data.motivo !== 'undefined' && form_data.motivo !== null && Number(form_data.motivo.id) === 1 ? 'required' : '')"
                        >
                          <b-form-group
                            :label="$t('Data da contra-proposta')"
                            :class="errors.length > 0 ? 'is-invalid':null"
                          >
                            <date-picker
                              v-model="dtaContra"
                              value-type="format"
                              :input-class="{'form-control' : true, 'is-invalid': errors.length > 0 }"
                              type="date"
                              :editable="false"
                              :clearable="true"
                              :lang="langCalendar"
                              :append-to-body="true"
                              prefix-class="sw"
                              :disabled-date="disabledMaxDate"
                            >
                              <template #icon-clear>
                                <v-select-deselect />
                              </template>
                            </date-picker>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>

                    <b-row>
                      <b-col md="12">
                        <validation-provider
                          #default="{ errors }"
                          :rules="(typeof form_data.motivo !== 'undefined' && form_data.motivo !== null && Number(form_data.motivo.id) === 1 ? 'required' : '')"
                        >
                          <b-form-group
                            :label="$t('Motivo')"
                            :class="errors.length > 0 ? 'is-invalid':null"
                          >
                            <b-form-textarea
                              v-model="txtMotivo"
                              placeholder=""
                              rows="4"
                              :class="errors.length > 0 ? 'is-invalid':null"
                            />
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </div>

                  <b-row class="mt-2">
                    <b-col md="12">
                      <h4>
                        {{ $t('Anexos') }}
                      </h4>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col md="12 mb-1">
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="outline-primary"
                        block
                        @click="$refs.refDocsProposal.$el.click()"
                      >
                        {{ $t('Carregar ficha de proposta digitalizada') }}
                      </b-button>
                      <b-form-file
                        ref="refDocsProposal"
                        accept="*"
                        :hidden="true"
                        plain
                        multiple
                        @input="uploadDocRender"
                      />
                    </b-col>
                  </b-row>

                  <div
                    v-for="(row, index) in aFilesProposal"
                    id="containerFiles"
                    :key="`docs-${index}`"
                    class="mt-1"
                  >
                    <b-row>
                      <b-col md="12">
                        <b-form-group
                          :label="$t('Documento')"
                        >
                          <div class="text-truncate txt-label text-primary text-break-word">
                            {{ row.name }}
                          </div>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12">
                        <b-form-group
                          :label="$t('Legenda')"
                        >
                          <b-form-input
                            v-model="row.legend"
                            type="text"
                            autocomplete="off"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col md="12">
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-danger"
                          class="btn-icon mb-1"
                          @click="eliminarDoc(index)"
                        >
                          <span class="align-middle">{{ $t('Eliminar') }}</span>
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>

                </validation-observer>
              </b-form>

            </b-container>

          </vue-perfect-scrollbar>
          <div
            class="d-flex p-1 justify-content-center"
          >
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click.stop.prevent="hide"
            >
              {{ $t('Fechar') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="ml-1"
              @click="sendCounterProposal"
            >
              {{ $t('Submeter contra-proposta') }}
            </b-button>
          </div>
        </b-overlay>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BContainer, BOverlay, VBTooltip, BRow, BCol, BFormTextarea, BFormGroup, BButton, BFormFile, BFormInput, BForm, BInputGroup,
} from 'bootstrap-vue'
import store from '@/store'
import '@/libs/sweet-alerts'
import { ref, onUnmounted } from '@vue/composition-api'
import crmProposalsFormModule from '@store-modules/crm/proposals/form'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { showModalDeleteMessage } from '@core/utils/utils'
import { CurrencyDirective } from 'vue-currency-input'
import { configMoney, symbolMoney } from '@core-custom/utils/money'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { mixFormGeral } from '../mixins'

const oDatepicker = () => Promise.all([
  import(/* webpackChunkName: "chunk-vue2-datepicker" */ 'vue2-datepicker'),
  import(/* webpackChunkName: "chunk-vue2-datepicker" */ '@/assets/scss/datepicker.scss'),
]).then(([DatePicker]) => DatePicker)

export default {
  components: {
    BSidebar,
    BContainer,
    BOverlay,
    BRow,
    BCol,
    BFormTextarea,
    BFormGroup,
    BButton,
    BFormFile,
    BFormInput,
    BForm,
    BInputGroup,
    vSelect,
    vSelectDeselect: vSelect.components.Deselect,
    VuePerfectScrollbar,
    ValidationObserver,
    ValidationProvider,
    DatePicker: oDatepicker,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    currency: CurrencyDirective,
  },
  mixins: [sizeIconsAction, mixFormGeral],
  props: {
  },
  data() {
    const now = new Date()
    const maxDate = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    const minDate = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    minDate.setDate(minDate.getDate() - 2)

    return {
      configMoney,
      symbolMoney,
      maxDate,
      minDate,
      langCalendar: {},
      aFilesUpload: {},
      aFilesProposal: [],
      resumeErrorUpload: [],
      aReforcos: [],
      txtMotivo: '',
      dtaContra: '',
      flagChangeComission: false,
    }
  },
  computed: {
    ...mapGetters('crm_proposals_form', ['typeBusiness', 'typeReasons', 'configApp', 'form_data', 'proposal']),
  },
  watch: {
    form_data: {
      handler(event) {
        if (typeof event.sw131s07 !== 'undefined' && event.sw131s07 !== '' && event.sw131s07 !== null) {
          const date1 = new Date(event.sw131s07)

          this.aReforcos.forEach((item, index) => {
            if (item.data !== '') {
              const dateR = new Date(item.data)
              if (date1 > dateR) {
                this.aReforcos[index].data = ''
              }
            }
          })

          if (typeof event.sw131s28 !== 'undefined' && event.sw131s28 !== '' && event.sw131s28 !== null) {
            const date2 = new Date(event.sw131s28)
            if (date1 > date2) {
              this.$store.dispatch('crm_proposals_form/updateFieldValue', { field: 'sw131s28', value: null })
            }

            this.aReforcos.forEach(item => {
              if (item.data !== '' && this.form_data.sw131s28 !== null) {
                const dateRe = new Date(item.data)
                if (dateRe > date2) {
                  this.$store.dispatch('crm_proposals_form/updateFieldValue', { field: 'sw131s28', value: null })
                }
              }
            })
          }
        }
      },
      deep: true,
    },
  },
  async created() {
    this.aReforcos.push({ valor: '', data: '' })
    this.processingAjax = false
  },
  async mounted() {
    await this.importLocale(this.$i18n.locale).then(res => {
      this.langCalendar = res.default
      // Forçei estes valores para a semana começar sempre no domingo
      this.langCalendar.formatLocale.firstDayOfWeek = 0
      this.langCalendar.formatLocale.firstWeekContainsDate = 4
    })
  },
  methods: {
    async closeModal(val) {
      await this.$store.commit('crm_proposals_form/setUpdateFormData', {})
      this.$parent.showFormCounterProposal = val
    },
    disabledMaxDate(date) {
      return (date > this.maxDate || date < this.minDate)
    },
    disabledMaxDateEntrega(date) {
      return date < this.maxDate
    },
    disabledMaxDateReforco(date) {
      if (typeof this.form_data.sw131s07 !== 'undefined' && this.form_data.sw131s07 !== null && this.form_data.sw131s07 !== '') {
        let maxDateEntrega = new Date(this.form_data.sw131s07)

        this.aReforcos.forEach((item, index) => {
          if (index === 0) {
            maxDateEntrega = new Date(this.form_data.sw131s07)
          } else if (this.aReforcos[index - 1].data !== '' && this.aReforcos[index - 1].data !== null) {
            maxDateEntrega = new Date(this.aReforcos[index - 1].data)
          }
        })

        return date < maxDateEntrega
      }

      let dateEntrega = this.maxDate
      this.aReforcos.forEach((item, index) => {
        if (index === 0) {
          dateEntrega = this.maxDate
        } else if (this.aReforcos[index - 1].data !== '' && this.aReforcos[index - 1].data !== null) {
          dateEntrega = new Date(this.aReforcos[index - 1].data)
        }
      })

      return date < dateEntrega
    },
    disabledMaxDateEscritura(date) {
      if (typeof this.form_data.sw131s07 !== 'undefined' && this.form_data.sw131s07 !== null && this.form_data.sw131s07 !== '') {
        let maxDateEscritura = new Date(this.form_data.sw131s07)

        this.aReforcos.forEach(item => {
          if (item.data !== '') {
            maxDateEscritura = new Date(item.data)
          }
        })

        return date < maxDateEscritura
      }

      const now = new Date()
      let maxDateEscritura = new Date(now.getFullYear(), now.getMonth(), now.getDate())

      this.aReforcos.forEach(item => {
        if (item.data !== '') {
          maxDateEscritura = new Date(item.data)
        }
      })

      return date < maxDateEscritura
    },
    async importLocale(lang) {
      return import(`vue2-datepicker/locale/${lang}`)
    },
    async sendCounterProposal(evt) {
      evt.preventDefault()
      const self = this

      if ((typeof self.clicked === 'undefined') || (self.clicked === false)) {
        self.clicked = true
        await this.$refs.formSaveProposalValidator.validate()
          .then(valid => {
            if (valid) {
              if (typeof this.form_data.motivo === 'undefined' || this.form_data.motivo === null || Number(this.form_data.motivo.id) === 0) {
                let vlEntrega = this.form_data.sw131s26
                vlEntrega = vlEntrega.replace(/\./g, '').replace(',', '.')

                if (Number(vlEntrega) > 0) {
                  if (typeof this.form_data.sw131s24 !== 'undefined' && this.form_data.sw131s24 !== null && this.form_data.sw131s24 !== '') {
                    if (typeof this.form_data.sw131s17 !== 'undefined' && this.form_data.sw131s17 !== null && this.form_data.sw131s17.length < 250) {
                      this.showMsgErrorRequest(new Error(this.$t('A justificação apresentada para alteração de comissão não possui o número limite de caracteres!')))
                    } else {
                      this.$swal({
                        title: '',
                        html: `${this.$t('A alteração de valores de comissão implica a validação dos {cargo}, deseja continuar?', { cargo: `${this.$store.getters['crm_proposals_form/cargo']}` })}`,
                        showCancelButton: true,
                        reverseButtons: true,
                        confirmButtonText: this.$t('Continuar sem documento'),
                        cancelButtonText: this.$t('Voltar'),
                        customClass: {
                          confirmButton: 'btn btn-danger ml-sm-1 btn-xs-block',
                          cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
                        },
                        buttonsStyling: false,
                      }).then(async result => {
                        if (result.value) {
                          if (this.aFilesProposal.length > 0 && this.aFilesProposal[0].name !== '') {
                            this.uploadDocs()
                          } else {
                            this.$swal({
                              title: '',
                              html: `${this.$t('Ao submeter sem anexar um documento da proposta digitalizada, a aprovação passa a depender dos {cargo}. Deseja continuar?', { cargo: `${this.$store.getters['crm_proposals_form/cargo']}` })}`,
                              showCancelButton: true,
                              reverseButtons: true,
                              confirmButtonText: this.$t('Continuar sem documento'),
                              cancelButtonText: this.$t('Voltar'),
                              customClass: {
                                confirmButton: 'btn btn-danger ml-sm-1 btn-xs-block',
                                cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
                              },
                              buttonsStyling: false,
                            }).then(async res => {
                              if (res.value) {
                                self.$root.$emit('app::loading', true)
                                this.saveCounterProposal()
                              }
                            })
                          }
                        }
                      })
                    }
                  } else if (this.aFilesProposal.length > 0 && this.aFilesProposal[0].name !== '') {
                    this.uploadDocs()
                  } else {
                    this.$swal({
                      title: '',
                      html: `${this.$t('Ao submeter sem anexar um documento da proposta digitalizada, a aprovação passa a depender dos {cargo}. Deseja continuar?', { cargo: `${this.$store.getters['crm_proposals_form/cargo']}` })}`,
                      showCancelButton: true,
                      reverseButtons: true,
                      confirmButtonText: this.$t('Continuar sem documento'),
                      cancelButtonText: this.$t('Voltar'),
                      customClass: {
                        confirmButton: 'btn btn-danger ml-sm-1 btn-xs-block',
                        cancelButton: 'btn btn-outline-secondary mb-1 mb-sm-0 btn-xs-block',
                      },
                      buttonsStyling: false,
                    }).then(async result => {
                      if (result.value) {
                        self.$root.$emit('app::loading', true)
                        this.saveCounterProposal()
                      }
                    })
                  }
                } else {
                  this.showMsgErrorRequest(new Error(this.$t('O valor do negócio não está certo com os valores de entrega!')))
                }
              } else if (this.aFilesProposal.length > 0 && this.aFilesProposal[0].name !== '') {
                this.uploadDocs()
              } else {
                this.submitCounterProposal()
              }
            } else {
              this.showMsgErrorRequest(new Error(this.$t('Preencha os campos obrigatórios')))
            }
          })
          .catch(() => {
            this.showMsgErrorRequest(new Error(this.$t('Preencha os campos obrigatórios')))
          })

        setTimeout(() => {
          self.clicked = false
        }, 1000)
      }
    },
    async saveCounterProposal() {
      await this.$store.dispatch('crm_proposals/saveNewFlowProposal', {
        sw130s01: btoa(this.$parent.proposalDetail.proposal.sw130s01), reforcos: this.aReforcos, files: this.aFilesProposal, formDados: this.form_data,
      }).then(async res => {
        this.$root.$emit('app::loading', false)
        this.showMsgSuccessRequest(res)

        this.$nextTick(() => {
          this.$parent.$refs.detailProposal.$refs.detailProposalScroll.$el.scrollTop = 0
        })

        let actionProposal = 'all'
        if (Number(this.$parent.proposalDetail.iWhoami) === 1 || Number(this.$parent.proposalDetail.iWhoami) === 3 || Number(this.$parent.proposalDetail.iWhoami) === 5) {
          actionProposal = 'sent'
        } else if (Number(this.$parent.proposalDetail.iWhoami) === 2 || Number(this.$parent.proposalDetail.iWhoami) === 3 || Number(this.$parent.proposalDetail.iWhoami) === 4) {
          actionProposal = 'received'
        }

        await this.$store.dispatch('crm_proposals/flowProposalObjects', { sw130s01: btoa(this.$parent.proposalDetail.proposal.sw130s01), action: actionProposal }).catch(error => {
          this.showMsgErrorRequest(error)
        })

        this.resetData()
        this.$parent.showFormCounterProposal = false
      }).catch(error => {
        this.$root.$emit('app::loading', false)
        this.showMsgErrorRequest(error)
      })
    },
    async submitCounterProposal() {
      let filename = ''
      const aFiles = []
      let sFilesUpload = ''
      const aLegendas = []
      let sLegendasFiles = ''

      if (this.aFilesProposal.length > 0) {
        this.aFilesProposal.forEach((file, index) => {
          if (Number(index) === 0) {
            filename = file.name
          }

          aFiles.push(file.name)

          if (typeof file.legend !== 'undefined') {
            aLegendas.push(file.legend)
          } else {
            aLegendas.push('')
          }
        })

        sFilesUpload = JSON.stringify(aFiles)
        sLegendasFiles = JSON.stringify(aLegendas)
      }
      await this.$store.dispatch('crm_proposals/counterProposal', {
        sw130s01: btoa(this.$parent.proposalDetail.proposal.sw130s01), sw131s16: this.dtaContra, sw131s17: this.txtMotivo, sw131s20: filename, sFiles: sFilesUpload, sLegendas: sLegendasFiles,
      }).then(async res => {
        this.$root.$emit('app::loading', false)
        this.showMsgSuccessRequest(res)

        this.$nextTick(() => {
          this.$parent.$refs.detailProposal.$refs.detailProposalScroll.$el.scrollTop = 0
        })

        let actionProposal = 'all'
        if (Number(this.$parent.proposalDetail.iWhoami) === 1 || Number(this.$parent.proposalDetail.iWhoami) === 3 || Number(this.$parent.proposalDetail.iWhoami) === 5) {
          actionProposal = 'sent'
        } else if (Number(this.$parent.proposalDetail.iWhoami) === 2 || Number(this.$parent.proposalDetail.iWhoami) === 3 || Number(this.$parent.proposalDetail.iWhoami) === 4) {
          actionProposal = 'received'
        }

        await this.$store.dispatch('crm_proposals/flowProposalObjects', { sw130s01: btoa(this.$parent.proposalDetail.proposal.sw130s01), action: actionProposal }).catch(error => {
          this.showMsgErrorRequest(error)
        })

        this.resetData()
        this.$parent.showFormCounterProposal = false
      }).catch(error => {
        this.$root.$emit('app::loading', false)
        this.showMsgErrorRequest(error)
      })
    },
    async updateField(event, flag) {
      if (typeof this.form_data.sw131s04 !== 'undefined') {
        let vlProposal = this.form_data.sw131s04 || parseFloat(0.00)
        if (flag) {
          vlProposal = event
        }

        let vlEntrega = this.form_data.sw131s06 || parseFloat(0.00)
        let vlReforcos = parseFloat(0.00)

        vlProposal = vlProposal.replace(/\./g, '').replace(',', '.')

        if (vlEntrega !== 0) {
          vlEntrega = vlEntrega.replace(/\./g, '').replace(',', '.')
        }

        this.aReforcos.forEach(item => {
          if (item.valor !== '') {
            const vlRef = item.valor.replace(/\./g, '').replace(',', '.')
            vlReforcos = parseFloat(vlReforcos) + parseFloat(vlRef)
          }
        })

        const vlAux = parseFloat(vlProposal) - parseFloat(vlEntrega) - parseFloat(vlReforcos)

        await this.$store.dispatch('crm_proposals_form/updateFieldValue', { field: 'sw131s26', value: vlAux.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') })
      }
    },
    async uploadDocs() {
      this.$root.$emit('app::loading', true)
      await this.$store.dispatch('crm_proposals_events/uploadDocs', { files: this.aFilesUpload }).then(async result => {
        this.resetFieldUpload()

        Object.keys(result).forEach(index => {
          if (result[index].error === false) {
            this.resumeErrorUpload.push(result[index])
          } else {
            this.aFilesProposal[index].name = result[index].filename
          }
        })
        this.saveCounterProposal()
      }).catch(error => {
        this.resetFieldUpload()
        this.showMsgErrorRequest(error)
      })
    },
    addReforco() {
      let bInsert = true
      this.aReforcos.forEach(item => {
        if (item.valor === '' || item.data === '') {
          bInsert = false
        }
      })

      if (bInsert) {
        this.aReforcos.push({ valor: '', data: '' })
      } else {
        this.showMsgErrorRequest({ message: this.$t('Preencha os campos de valor e data') })
      }
    },
    async removeReforco(index) {
      const actionModal = new Promise(resolve => {
        showModalDeleteMessage(resolve, `${this.$t('Pretende eliminar este reforço?')}<br>${this.$t('Esta ação é irreversível!')}`)
      })

      await actionModal.then(result => {
        if (result === 'confirm') {
          this.aReforcos.splice(index, 1)
        }
      })
    },
    changeData(date) {
      if (typeof this.form_data.sw131s28 !== 'undefined' && this.form_data.sw131s28 !== '' && this.form_data.sw131s28 !== null) {
        const date1 = new Date(date)
        const date2 = new Date(this.form_data.sw131s28)
        if (date1 > date2) {
          this.$store.dispatch('crm_proposals_form/updateFieldValue', { field: 'sw131s28', value: null })
        }
      }

      this.aReforcos.forEach((item, index) => {
        if (typeof this.aReforcos[index + 1] !== 'undefined' && this.aReforcos[index + 1].data !== '' && this.aReforcos[index + 1].data !== null) {
          if (item.data !== '') {
            const date1 = new Date(item.data)
            const date2 = new Date(this.aReforcos[index + 1].data)
            if (date1 > date2) {
              this.aReforcos[index + 1].data = ''
            }
          } else {
            this.aReforcos[index + 1].data = ''
          }
        }
      })
    },
    resetFieldUpload() {
      this.resumeErrorUpload = []
      this.$refs.refDocsProposal.reset()
    },
    resetData() {
      this.aFilesUpload = {}
      this.aFilesProposal = []
      this.resumeErrorUpload = []
      this.aReforcos = []
      this.txtMotivo = ''
      this.dtaContra = ''
      this.$store.dispatch('crm_proposals_form/resetFormData')
    },
    async uploadDocRender(aFiles) {
      if (aFiles.length > 0) {
        this.aFilesUpload = aFiles
        for (let index = 0; index < aFiles.length; index += 1) {
          this.aFilesProposal.push({ name: aFiles[index].name, legend: '' })
        }

        let container = this.$refs.scrollForm.$el.scrollTop
        container = Number(container + document.body.scrollHeight)

        this.$nextTick(() => {
          this.$refs.scrollForm.$el.scrollTop = container
        })

        if (aFiles.length === 1) {
          this.showMsgSuccessRequest({ message: this.$t('Ficheiro carregado com sucesso') })
        } else {
          this.showMsgSuccessRequest({ message: this.$t('Ficheiros carregados com sucesso') })
        }
      }
    },
    async eliminarDoc(index) {
      const actionModal = new Promise(resolve => {
        showModalDeleteMessage(resolve, `${this.$t('Pretende eliminar este documento?')}<br>${this.$t('Esta ação é irreversível!')}`)
      })

      await actionModal.then(result => {
        if (result === 'confirm') {
          this.aFilesProposal.splice(index, 1)
        }
      })
    },
  },
  setup() {
    const CRM_PROPOSALS_MODULE_NAME_FORM = 'crm_proposals_form'

    if (!store.hasModule(CRM_PROPOSALS_MODULE_NAME_FORM)) {
      store.registerModule(CRM_PROPOSALS_MODULE_NAME_FORM, crmProposalsFormModule)

      onUnmounted(() => {
        if (store.hasModule(CRM_PROPOSALS_MODULE_NAME_FORM)) store.unregisterModule(CRM_PROPOSALS_MODULE_NAME_FORM)
      })
    }

    const refDocsProposal = ref(null)

    return {
      refDocsProposal,
      configSettingsScroll,
    }
  },
}
</script>

<style lang="scss" scoped>

.force-padding-left-05{
  padding-left: 0.5rem !important;
}

.force-padding-0{
  padding:0 !important;
}

</style>
